<script setup>
import { Head, Link, usePage } from '@inertiajs/vue3';
import MainLayout from '../../Layouts/MainLayout.vue';

const page = usePage();
const auth = page?.props?.auth || { user: null };
</script>

<template>
    <MainLayout title="Lost in Transit">
        <div class="w-full min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-primary to-secondary text-white text-center px-6">
            <!-- Travel-Themed Illustration -->
            <img src="/assets/svg/404.svg" class="w-80 max-w-full mb-6" alt="Lost in Travel">

            <!-- Heading -->
            <h1 class="text-6xl font-extrabold drop-shadow-lg">Oops! You're Off the Map!</h1>

            <!-- Creative Travel-Themed Message -->
            <p class="text-xl mt-4 max-w-2xl leading-relaxed">
                It looks like you've taken a wrong turn on your journey. <br>
                No worries, our travel support team is here to **guide you back to safety!** ✈️🌍
            </p>

            <!-- Buttons to Guide User Back -->
            <div class="mt-8 flex flex-col md:flex-row gap-4">
                <Link
                    href="/"
                    class="px-6 py-3 bg-yellow-500 hover:bg-yellow-400 text-gray-900 font-semibold rounded-lg shadow-lg transition-all duration-300"
                >
                    🏠 Back to Home
                </Link>
                <Link
                    href="/contact"
                    class="px-6 py-3 bg-white hover:bg-gray-200 text-primary font-semibold rounded-lg shadow-lg transition-all duration-300"
                >
                    📞 Contact Support
                </Link>
            </div>

        </div>
    </MainLayout>
</template>

<style scoped>
/* Animated Background Effect */
@keyframes moveClouds {
    0% { background-position: 0 0; }
    100% { background-position: 1000px 500px; }
}

/* Updated Gradient Background */
.bg-gradient-to-r {
    background: linear-gradient(135deg, #034328 0%, #17c470 100%);
    animation: moveClouds 20s linear infinite;
}
</style>
